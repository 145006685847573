import DetailBox from './DetailBox'; // 档案头部框架
import ActivityRule from './ActivityRule'; // 档案地图活动规律
import ColleagueModal from './ColleagueModal'; //常去地点模态框

const ArchivesComponents = {
  DetailBox,
  ActivityRule,
  ColleagueModal
};

export default ArchivesComponents;

import React from "react";
import { Radio, message } from "antd";
import { sortBy } from "lodash";
import FrequentPlace from "./components/FrequentPlace";
import PropTypes from "prop-types";

import "./index.less";

const RadioGroup = Radio.Group;
const IconFont = Loader.loadBaseComponent("IconFont");
const Button = Loader.loadBaseComponent("LButton", "LButton");
const PersonPathGraph = Loader.loadBusinessComponent("MapComponent", "PersonPathGraph");
/**
 * @param {Function} queryTrackCount 获取抓拍记录
 * @param {Function} changeCOllModal 打开抓拍记录模态框
 * @param {Object} data 车辆基本信息
 * @param {Array} trackCount 抓拍记录
 */

@Decorator.businessProvider("tab")
class ActivityRule extends React.Component {
  static propTypes = {
    trackCount: PropTypes.array,
    frequentList: PropTypes.array,
    data: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      value: 7,
      fullScreenState: false,
      currentPlace: ""
    };
    this.trajectory = null;
    this.ActivityRuleMap = React.createRef();
  }

  componentDidMount() {
    this.props.queryTrackCount(7);
  }
  componentWillUnmount() {
    this.trajectory = null;
    this.ActivityRuleMap = null;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.trackCount !== this.props.trackCount) {
      console.log(nextProps.trackCount,this.props.trackCount)
      // let trackCount = this.filterTrackCount(nextProps.trackCount);
      let trackCount = nextProps.trackCount;
      let frequentLocation = nextProps.frequentLocation || [];
      let frequentTop5 = sortBy(frequentLocation, "frequency")
        .reverse()
        .slice(0, 5);
      this.getFrequentLocations(frequentTop5)
        .then(data => this.trajectory && this.trajectory.createMapGraph(trackCount, data))
        .catch(() => this.trajectory && this.trajectory.createMapGraph(trackCount, frequentTop5));
    }
  }

  filterTrackCount = trackCount => {
    if (!trackCount) {
      return [];
    }
    let arr = trackCount.filter(v => !!v.position[0] && !!v.position[1]); // 过滤坐标为空的抓拍记录
    arr.forEach(v => {
      if (Array.isArray(v.list)) {
        v.list = v.list.filter(v => !!v.position[0] && !!v.position[1]);
      }
    });
    return arr;
  };

  onChange = e => {
    let { queryTrackCount } = this.props;
    this.setState({ value: e.target.value });
    queryTrackCount(e.target.value);
  };

  //获取带边界的常去地点
  getFrequentLocations = async frequentTop5 => {
    let frequentTop5s = [];
    if (frequentTop5.length) {
      for (let i = 0; i < frequentTop5.length; i++) {
        let item = frequentTop5[i];
        let polyline = [];
        let center = item.center;
        let res = await Service.place.placesExt({ id: item.placeId });
        if (res.data && res.data.polyline) {
          polyline = JSON.stringify(res.data.polyline.split(";").map(v => v.split(",").map(x => x * 1)));
          center = res.data.center;
        } else {
          polyline = null;
        }
        frequentTop5s.push({ ...item, polyline, center });
      }
    }
    return frequentTop5s;
  };

  init = trajectory => {
    const { frequentLocation = [], trackCount } = this.props;
    // let trackCounts = this.filterTrackCount(trackCount);
    let trackCounts = trackCount;
    this.trajectory = trajectory;
    let frequentTop5 = sortBy(frequentLocation, "frequency")
      .reverse()
      .slice(0, 5);
    this.getFrequentLocations(frequentTop5)
      .then(data => this.trajectory && this.trajectory.createMapGraph(trackCounts, data))
      .catch(() => this.trajectory && this.trajectory.createMapGraph(trackCounts, frequentTop5));
  };

  goTrave = () => {
    const { tab, location, frequentList = [] } = this.props;
    console.log("frequentList", frequentList);
    if (frequentList.length === 0) {
      return;
    }
    const id = Utils.uuid();
    LM_DB.add("parameter", {
      id,
      list: frequentList,
      type: "vehicle"
    }).then(() => {
      tab.goPage({
        moduleName: "resourceTrajectory",
        location,
        data: { id }
      });
    });
  };

  clickPlace = parms => {
    const { changeCOllModal } = this.props;
    changeCOllModal && changeCOllModal(1, parms);
  };

  checkOutScreen = () => {
    let { fullScreenState } = this.state;
    if (fullScreenState) {
      this.setState({ fullScreenState: false }, () => Utils.exitFullscreen());
    } else {
      this.setState({ fullScreenState: true }, () => Utils.fullscreen(this.ActivityRuleMap.current));
    }
  };

  changePlace = currentPlace => {
    if (currentPlace.center) {
      this.trajectory.amap.setZoomAndCenter(17, currentPlace.center.split(","));
    } else {
      message.warn("当前常去地暂无经纬度");
    }
    this.setState({ currentPlace }, () => {
      if (currentPlace.center) {
        this.trajectory.updatePlaceMarkers(currentPlace);
      }
    });
  };

  render() {
    const { currentPlace } = this.state;
    const { trackCount = [], frequentList = [], frequentLocation, trackCountDays, passDeviceList } = this.props;
    const FRE_LEN = frequentList.length;
    return (
      <div className="activity-rule">
        <div className="rule-header">
          <div className="title">活动规律：</div>
          <div className="flutter">
            <RadioGroup onChange={this.onChange} defaultValue={7}>
              <Radio value={7}>近一周</Radio>
              <Radio value={30}>近一月</Radio>
            </RadioGroup>
          </div>
        </div>
        <div className={this.state.fullScreenState ? "rule-content fullScreen" : "rule-content"} ref={this.ActivityRuleMap}>
          <Button className="screen" onClick={() => this.checkOutScreen()}>
            <IconFont type={!this.state.fullScreenState ? "icon-S_View_FullScreen" : "icon-S_View_ExitFullScreen"} />
          </Button>
          {frequentLocation.length > 0 && (
            <FrequentPlace
              changePlace={this.changePlace}
              currentPlace={currentPlace}
              frequentLocation={frequentLocation}
              trackCountDays={trackCountDays}
            />
          )}
          <PersonPathGraph
            init={this.init}
            hasInfo={true}
            passDeviceList={passDeviceList}
            clickPlace={this.clickPlace}
            changePlace={this.changePlace}
            currentPlace={currentPlace}
            resetToolsClassName={frequentLocation.length > 0 ? "person-reset-tool" : "person-reset-tool-no"}
          />
          {trackCount.length > 0 && (
            <div className={`go-trave ${!!FRE_LEN ? "" : "go-wait"}`} onClick={this.goTrave}>
              <IconFont type="icon-S_Bar_Trajectory" theme="outlined" />
              <p className="trave-span">播放轨迹</p>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ActivityRule;

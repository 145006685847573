import React from 'react';
import './index.less';

class DetailBox extends React.Component {
  constructor(props){
    super(props);
  }

  render() {
    const {title = '', className = '', renderItem = null, children} = this.props;
    return (
      <div className={`detail-box ${className}`}>
        <div className='detail-box-header'>
          <div className='header-title'>
            {title}
          </div>
          <div className='header-render'>
            { renderItem }
          </div>
        </div>
        <div className='detail-box-content'>
          {children}
        </div>
      </div>
    )
  }
}

export default DetailBox;
import React from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import "./index.less";
import { message } from "antd";

const ModalComponent = Loader.loadBaseComponent("ModalComponent");
const IconFont = Loader.loadBaseComponent("IconFont");
const NoData = Loader.loadBaseComponent("NoData");
const CaptureCard = Loader.loadBusinessComponent("Card", "CaptureCard");

/**
 * @desc 抓拍记录与同行模态框
 * @param {number} type 1 抓拍记录 2 同行详情
 * @param {Array} collList 列表
 * @param {Function} onCancel 关闭模态框
 * @param {Function} onOk  还没用 先留着 （说不定哪天用着了）
 */

@withRouter
@Decorator.businessProvider("tab", "user", "place", "menu")
class ColleagueModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collList: []
    };
  }

  componentDidMount() {
    this.getList(this.props.currentData);
  }

  componentWillReceiveProps(nextProps) {
    const { currentData } = this.props;
    if (nextProps.currentData !== currentData) {
      if (nextProps.currentData.list && nextProps.currentData.list.length > 0) {
        this.getList(nextProps.currentData);
      }
    }
  }

  getList = (option = {}) => {
    const { collType } = this.props;
    let arr = {},
      cids = [];
    let list = option.list || [];
    list.forEach(v => {
      cids.push(v.cid);
      let time = null;
      if (collType === 1) {
        time = moment(+v.captureTime).format("YYYY.MM.DD");
      } else {
        time = moment(+v.aid1CaptureTime).format("YYYY.MM.DD");
      }
      if (arr[time]) {
        arr[time].count += 1;
        arr[time].list.push(v);
      } else {
        arr[time] = {
          count: 1,
          list: [v]
        };
      }
    });
    this.setState({
      collList: arr,
      title: option.title,
      total: option.count
    });
  };

  onClick = () => {
    const { collType, tab, location, handleCollCancel, currentData, roomList, user, place } = this.props;
    if (collType === 1) {
      Service.organization
        .getPlaceIdsByUserId({
          organizationId: user.userInfo.organizationId
        })
        .then(res => {
          let placeArray = place.getPlaceInfoByIds(res.data.placeIds);
          let data = placeArray.find(v => v.placeId === currentData.placeId);
          if (data) {
            const id = Utils.uuid();
            window.LM_DB.add("parameter", {
              id,
              placeId: currentData.placeId
            }).then(() => {
              tab.goPage({
                moduleName: "placeFile",
                location,
                data: { id }
              });
            });
          } else {
            message.warn("您当前未被分配该场所的查看权限");
          }
        });
    } else {
      let aid = currentData.title;
      Service.person.queryPersonBindInfo({ aid, perosnId: "" }).then(res => {
        const { personId, aids = [] } = res.data;
        let moduleName = "",
          id = "",
          url = "",
          type = "";
        const AIDS_LEN = aids.length > 0;
        if (personId && AIDS_LEN) {
          id = personId;
          type = "ploy";
        }
        if (personId && !AIDS_LEN) {
          id = personId;
          type = "entry";
        }
        if (!personId && !AIDS_LEN) {
          id = aid;
          type = "aid";
        }
        moduleName = "objectMapPersonnelDetail";
        url = roomList.find(v => v.accoompanyAid === aid).personInfoUrl;

        tab.openDetailPage({
          moduleName,
          value: id,
          data: { id },
          beforeAction: async () => {
            await window.LM_DB.add("parameter", { id, url, type });
          }
        });
      });
    }
    handleCollCancel && handleCollCancel(collType);
  };
  render() {
    const { collType = 2, collVisible, handleCollCancel, trackCountDays = 7, currentData, menu } = this.props;
    const { collList, title } = this.state;
    let hasMenu = menu.getInfoByName("placeFile");
    return (
      <ModalComponent
        className="personnel-collegue-modal"
        visible={collVisible}
        onOk={() => handleCollCancel && handleCollCancel()}
        onCancel={() => handleCollCancel && handleCollCancel()}
        otherModalFooter={true}
        width={collType === 1 ? "850px" : "970px"}
        title={collType === 1 ? "常去地点" : "同行详情"}
      >
        <div className="collegue-modal">
          <div className="header">
            <div className="address">
              <p className="address-label">
                {collType === 1 ? "地点名称：" : "虚拟身份："} {title}
              </p>
              {collType === 1 && (
                <p className="address-label">
                  出现次数：共出现
                  <span className="value">{currentData.count}</span>次
                </p>
              )}
              <p className="address-label">
                {collType === 1 ? `出现天数：${trackCountDays}天内出现` : "同行数：30天内同行"}
                <span className="value">{currentData.days}</span>
                {collType === 1 ? "天" : "次"}
              </p>
            </div>
            {hasMenu && (
              <div className="header-go" onClick={this.onClick}>
                <IconFont collType={"icon-S_Bar_NameCard"} theme="outlined" />
                进入档案查看更多
              </div>
            )}
          </div>
          <div className="collague-content">
            {!!Object.keys(collList).length ? (
              Object.keys(collList).map((v, index) => (
                <div className="time-case">
                  <div className="case-header">
                    {collType === 1 ? `${v}（共出现 ${collList[v].count} 次）` : `第${index + 1}次同行`}
                  </div>
                  <div className="case-content">
                    {!!collList[v].list.length &&
                      collList[v].list.map(item =>
                        collType === 1 ? (
                          <CaptureCard
                            className="detail-box-item"
                            relativeIcon=""
                            hoverScale={false}
                            captureTime={item.captureTime || v.openTime}
                            deviceName={item.deviceName || v.address}
                            imgUrl={item.faceUrl || item.videoUrl || item.vehicleUrl}
                          />
                        ) : (
                          <div className="case-content-double">
                            <CaptureCard
                              className="detail-box-item"
                              relativeIcon=""
                              hoverScale={false}
                              captureTime={item.aid1CaptureTime}
                              deviceName={item.deviceName}
                              imgUrl={item.aid1FaceUrl}
                            />
                            <CaptureCard
                              className="detail-box-item"
                              relativeIcon=""
                              hoverScale={false}
                              captureTime={item.aid2CaptureTime}
                              deviceName={item.deviceName}
                              imgUrl={item.aid2FaceUrl}
                            />
                          </div>
                        )
                      )}
                    {[1, 2, 3, 4].map(v => {
                      return collType === 1 ? <div className="single-box-null" /> : <div className="doruble-box-null" />;
                    })}
                  </div>
                </div>
              ))
            ) : (
              <NoData />
            )}
          </div>
        </div>
      </ModalComponent>
    );
  }
}

export default ColleagueModal;

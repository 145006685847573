import React from "react";
import { Progress } from "antd";
import { sortBy } from "lodash";
import "./index.less";

const IconFont = Loader.loadBaseComponent("IconFont");
const NoData = Loader.loadBaseComponent("NoData");

class FrequentPlace extends React.Component {
  getData = () => {
    const { frequentLocation = [] } = this.props;
    let data = sortBy(frequentLocation, "frequency")
      .reverse()
      .slice(0, 5);
    return data
  }

  changeActive = item => {
    this.props.changePlace && this.props.changePlace(item)
  };

  getPlaceRender = ({ placeId, placeName, frequency,days,...arg }, index,trackCountDays) => (
    <div
      key={placeId}
      className={placeId === this.props.currentPlace.placeId ? "place active" : "place"}
      onClick={() => this.changeActive({ placeId, placeName, frequency,days,...arg })}
    >
      <div className="p-sub-title" title={placeName}>
        <span>{index + 1}</span>
        <strong>{Utils.getSubStr(placeName)}</strong>
      </div>
      <div className="p-sub-content">
        <div className="p-info">
          <p>
            共出现<span className='num'>{frequency}</span>次
          </p>
          <p>
            {trackCountDays}天内出现<span className='num'>{days}</span>天
          </p>
        </div>
        <Progress
          type="circle"
          size="small"
          format={() => `${days} 天`}
          strokeWidth={5}
          width={46}
          percent={days && days > 7 ? parseInt( days/30*100) : parseInt( days/7*100)}
        />
      </div>
    </div>
  );

  render() {
    let {trackCountDays} = this.props
    let data = this.getData()
    return (
      <div className="Frequent-place">
        <div className="p-title">
          <IconFont type="icon-S_Bar_PeopleOther" />
          常去地点
        </div>
        <div className="p-content">
          {data.length > 0 ? (
            data.map((v, i) => this.getPlaceRender(v, i,trackCountDays))
          ) : (
            <NoData key="frequent" pdt={120} />
          )}
        </div>
      </div>
    );
  }
}

export default FrequentPlace;
